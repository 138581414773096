export function convertDateTimeLocalToOnlyDateUTCforBC(dateIn: Date): Date {
  const date = new Date(dateIn);

  // Extract the year, month, and date parts
  const year = date.getFullYear();
  const month = date.getMonth(); // Note: Month is zero-based (0 = January, 1 = February, etc.)
  const day = date.getDate();

  // Create a new Date object for the desired date at midnight in local time
  return new Date(Date.UTC(year, month, day));
}
